import React, { useState, useEffect } from 'react'
import { object } from 'prop-types'
import useMedia from 'use-media'
import { graphql } from 'gatsby'
import { useQueryParam, StringParam } from 'use-query-params'

import Layout from '../layout'
import { SEO, Update, Pagination, SearchBar } from '../components'
import { getUpdates } from '../utils'

export const query = graphql`
  query UpdatesListQuery($category: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { regex: $category } } }
    ) {
      nodes {
        frontmatter {
          slug
          author
          title
          date(formatString: "DD MMMM, YYYY")
          categories
          ogimage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        excerpt(pruneLength: 250)
        fields {
          index
        }
      }
      totalCount
    }
  }
`

const updatesLimit = 10

const UpdatesList = ({ data, pageContext }) => {
  const isWide = useMedia({ minWidth: 900 })

  const [paramQuery, setParamQuery] = useQueryParam('query', StringParam)
  const [paramCategory, setParamCategory] = useQueryParam(
    'category',
    StringParam
  )

  const [query, setQuery] = useState(paramQuery || '')
  const [selectedCategory, setSelectedCategory] = useState(
    paramCategory?.split(encodeURIComponent(',')) || []
  )

  useEffect(
    () => {
      if (query) {
        setParamQuery(query)
      }
    },
    [query, setParamQuery]
  )

  useEffect(
    () => {
      if (selectedCategory.length > 0) {
        setParamCategory(selectedCategory?.join(','))
      }
    },
    [selectedCategory, setParamCategory]
  )

  const {
    title,
    description,
    category: pageCategory,
    currentPage,
    skip,
  } = pageContext

  const categorySlug =
    pageCategory === '//' ? 'all' : pageCategory.toLowerCase()

  const allUpdates = data.allMarkdownRemark.nodes.map(node => ({
    ...node.frontmatter,
    excerpt: node.excerpt,
    index: node.fields.index,
  }))

  const { numberOfPages, updates } = getUpdates(
    allUpdates,
    skip,
    updatesLimit,
    query,
    selectedCategory
  )

  return (
    <Layout>
      <SEO title={title} description={description} image="" />
      <div className="u-Pt-md md:u-Pt-xl u-bg-black-2">
        <section className="o-container u-Mb-md md:u-Mb-xl">
          <h3 className="u-text-p2">{title}</h3>
          {description && (
            <h4
              className="u-text-p6 u-mb-xl"
              style={{ width: isWide ? '50%' : '100%' }}
            >
              {description}
            </h4>
          )}
          <SearchBar
            placeholder="Search for updates..."
            onSearchChange={setQuery}
            query={query}
            setCategory={setSelectedCategory}
            category={selectedCategory}
            pageCategory={categorySlug}
          />
        </section>

        {updates.map((node, i) => {
          const { slug, title, author, date, excerpt, ogimage, index } = node
          return (
            <Update
              key={`${node.title}-${i}`}
              slug={slug}
              index={index}
              title={title}
              author={author[0]}
              date={date}
              excerpt={excerpt}
              image={ogimage}
              marginBottom
            />
          )
        })}
      </div>
      {numberOfPages > 1 && (
        <Pagination
          currentPage={currentPage}
          numPages={numberOfPages}
          category={categorySlug}
          query={query}
        />
      )}
    </Layout>
  )
}

UpdatesList.propTypes = {
  data: object,
  pageContext: object,
}

export default UpdatesList
