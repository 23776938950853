import React from 'react'
import { node, string, bool } from 'prop-types'

import { Header, Main, Footer, PromoBanner, CourseFooter } from '../components'
import { promotion } from '../config'
import './styles/index.less'

const Layout = ({ className, children, isCourseSite }) => {
  const isBannerVisible = !isCourseSite && promotion.videoCourseBanner.isActive

  return (
    <div className={className}>
      {isBannerVisible && (
        <PromoBanner>{promotion.videoCourseBanner.body}</PromoBanner>
      )}
      <Header isCourseSite={isCourseSite} />
      <Main>{children}</Main>
      {isCourseSite ? <CourseFooter /> : <Footer />}
    </div>
  )
}

Layout.propTypes = {
  children: node,
  className: string,
  isCourseSite: bool,
}

export default Layout
